<template>
  <div class="HistoricalRecord" v-loading="loginLoading">
    <h2
      style="
        color: #fff;
        display: flex;
        padding: 0 10px;
        align-items: center;
        justify-content: space-between;
      "
    >
      签收记录
      <el-button v-show="!fromSmall" size="small" @click="changePhone">更换手机号</el-button>
    </h2>
    <div></div>
    <div class="list" v-infinite-scroll="loadData" infinite-scroll-distance="1">
      <div
        v-for="(item, index) in data"
        :key="index"
        class="listItem"
        @click="toDetail(item.Id)"
      >
        <div class="flex">
          <span>销售方:</span>
          <b>{{ item.Customer }}</b>
        </div>
        <div class="flex">
          <span>货单号:</span>
          <b>{{ item.BillId }}</b>
        </div>
        <div class="flex">
          <span>日期:</span>
          <b>{{ item.SigningDate? item.SigningDate.substring(0, 19):"" }}</b>
        </div>
      </div>
      <div v-show="loading" class="tips">加载中...</div>
      <div v-show="data.length >= pagination.total" class="tips">
        没有更多了
      </div>
    </div>
    <el-dialog
      title="请进行登录"
      :visible.sync="dialogVisible"
      width="70%"
      :before-close="handleClose"
    >
      <div>
        <el-input
          v-model.number.trim="phone"
          placeholder="请输入手机号"
          maxlength="11"
        ></el-input>
        <el-input
          style="margin-top: 20px"
          v-model="password"
          placeholder="请输入密码"
          type="password"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
const regExp = new RegExp("^1[3456789]\\d{9}$"); // 手机号正则
export default {
  data() {
    return {
      data: [],
      loading: false,
      loginLoading: false,
      dialogVisible: false,
      phone: "",
      password: "",
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
      },
      fromSmall: false, // 是否小程序进入
    };
  },
  mounted() {
    if (this.$route.query.phone) {
      this.fromSmall = true;
      this.phone = this.$route.query.phone
      this.$http
        .post("/BO/CustomerLinkman/WxLinkmanLogin", { SignInPhone: this.phone })
        .then((res) => {
          if (res.Success) {
            this.getData();
          } else {
            this.$message.error(res.Msg);
          }
        });
      return;
    }
    let phone = localStorage.getItem("mobilePhone");
    let password = localStorage.getItem("mobilePassword");
    if (phone) {
      this.phone = phone;
      this.password = password;
      this.login();
      return;
    }
    this.dialogVisible = true;
  },
  methods: {
    changePhone() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleConfirm() {
      if (!regExp.test(this.phone))
        return this.$message.error("请输入正确的手机号");
      if (!this.password) return this.$message.error("请输入密码");
      this.login();
    },
    login() {
      this.loginLoading = true;
      this.$http
        .post("/BO/CustomerLinkman/CustomerLinkmanLogin", {
          SignInPhone: this.phone,
          SignInSmsCode: this.password,
        })
        .then((res) => {
          this.loginLoading = false;
          if (res.Success) {
            localStorage.setItem("mobilePhone", this.phone);
            localStorage.setItem("mobilePassword", this.password);
            this.dialogVisible = false;
            this.getData();
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    getData() {
      this.$http
        .post("/BO/CustomerLinkman/GetWXBillHistory", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          Search: {
            Keyword: this.phone,
          },
        })
        .then((res) => {
          console.log(res);
          this.loading = false;
          if (res.Success) {
            this.data = res.Data;
            this.pagination.total = res.Total;
          }
        });
    },
    loadData(e) {
      if (this.data.length >= this.pagination.total) return;
      this.pagination.current++;
      this.loading = true;
      this.$http
        .post("/BO/CustomerLinkman/GetWXBillHistory", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          Search: {
            Keyword: this.phone,
          },
        })
        .then((res) => {
          console.log(res);
          this.loading = false;
          if (res.Success) {
            this.data = this.data.concat(res.Data);
            this.pagination.total = res.Total;
          }
        });
    },
    toDetail(Id) {
      this.$router.push("/SendSMSMobile?id=" + Id);
    },
  },
};
</script>

<style lang="less" scoped>
.flex {
  display: flex;
}
.HistoricalRecord {
  padding: 10px;
  padding-top: 30px;
  background: #3362ec;
  height: 100%;
  h2 {
    font-weight: bold;
    margin-bottom: 20px;
  }
  .list {
    font-size: 14px;
    // background: #fff;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 10px;
    height: calc(100vh - 120px);
    overflow-y: auto;
    .listItem {
      margin: 10px 0;
      line-height: 3;
      border-radius: 5px;
      box-shadow: 0 1px 3px 1px #fff;
      box-sizing: border-box;
      padding: 0 10px;
      background: #fff;
      span {
        width: 60px;
        color: #000;
      }
      b {
        flex: 1;
      }
    }
    .tips {
      text-align: center;
      color: #fff;
    }
  }
}
</style>